const EWELINK_EMAIL_KEY = 'EWELINK_EMAIL';
const EWELINK_PASSWORD_KEY = 'EWELINK_PASSWORD';
const EWELINK_REGION_KEY = 'EWELINK_REGION';
const EWELINK_REGIONS = {
  EU: 'eu',
  US: 'us',
};

const DEVICE_SERVICE_ID = 'ewelink';
const DEVICE_EXTERNAL_ID_BASE = 'ewelink';

const DEVICE_IP_ADDRESS = 'IP_ADDRESS';
const DEVICE_FIRMWARE = 'FIRMWARE';
const DEVICE_ONLINE = 'ONLINE';

module.exports = {
  EWELINK_EMAIL_KEY,
  EWELINK_PASSWORD_KEY,
  EWELINK_REGION_KEY,
  EWELINK_REGIONS,
  DEVICE_SERVICE_ID,
  DEVICE_EXTERNAL_ID_BASE,
  DEVICE_IP_ADDRESS,
  DEVICE_FIRMWARE,
  DEVICE_ONLINE,
};
